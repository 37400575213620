import { AnalyticsBrowser } from "@segment/analytics-next";
import * as Sentry from "@sentry/nextjs";
import React from "react";
import { getPersonType } from "utils/Analytics/ABTestingHelper";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import config from "utils/config";
import zipy from "zipyai";

const AnalyticsContext = React.createContext<AnalyticsBrowser>(undefined!);

type Props = {
  writeKey: string;
  children: React.ReactNode;
};

type Traits = {
  name: string;
};

const analytics = AnalyticsBrowser.load({
  writeKey: config.SEGMENT_ID,
});

export const getTraitsObj = (user: any): any => {
  const {
    id,
    first_name,
    last_name,
    username,
    title,
    description,
    phone,
    profile_pic,
    email,
    name,
    date_joined: dateJoined,
    is_email_verified: isEmailVerified,
    profile_completion: profileCompletion,
    is_featured: isFeatured,
    service_added: serviceAdded,
    slots_added: slotsAdded,
  } = user;
  const traitsObj = {
    id: id || "NA",
    firstName: first_name || "NA",
    lastName: last_name || "NA",
    username: username || "NA",
    name: name ? name : `${first_name} ${last_name}` || "NA",
    title: title || "NA",
    description: description || "NA",
    email: email || "NA",
    phone: phone || "NA",
    avatar: profile_pic || "NA",
    createdAt: dateJoined || "NA",
    isEmailVerified: isEmailVerified,
    profileCompletion: profileCompletion || "NA",
    isFeatured: isFeatured,
    serviceAdded: serviceAdded,
    slotsAdded: slotsAdded,
    personType: getPersonType(),
  };
  return traitsObj;
};

export const insertSpaces = (text: string): string => {
  let inputText = text;
  inputText = inputText.replace(/([a-z])([A-Z])/g, "$1 $2");
  inputText = inputText.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
  return inputText;
};

const Track = (name: string, props = {}): void => {
  analytics.track(name, props);
};

const Page = (name: string, props = {}): void => {
  analytics.page(name, props);
};

const Identify = (id: string, traits: Traits): void => {
  analytics.identify(id, traits);
};

const Alias = (name: string): void => {
  analytics.alias(name);
};

export const identifyUser = (id, traits): void => {
  zipy.identify(id.toString(), traits);
  Analytics.identify(id, traits);
  Sentry.setUser(traits);
};

export const AnalyticsProvider = ({ children }: Props) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const SegmentAnalytics = {
  track: (name: string, props = {}): void => Track(name, props),
  identify: (id: string, traits: any): void => Identify(id, traits),
  alias: (name: string): void => Alias(name),
  page: (name: string, props = {}): void => Page(name, props),
};
