var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import config from "utils/config";

Sentry.init({
  dsn:
    config.SENTRY_DSN ||
    "https://56e4c474d4b04b91be245be9d892e7bf@o4504180611547136.ingest.sentry.io/4504343146921984",
  // Adjust this value in production, or use tracesSampler for greater control
  environment: config.IS_PROD === "true" ? "production" : "staging",
  enabled: true,
  // config.IS_PROD === "true" ? true : false,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  sampleRate: 0.1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
