/**
 * This method returns the person type for A/B testing
 *
 */
export const getPersonType = () => {
  return localStorage.getItem("personType");
};

/**
 * This method sets the person type for A/B testing
 * @param {*} personType
 */
export const setPersonType = (personType: any, force = false) => {
  if (!getPersonType() || force) {
    localStorage.setItem("personType", personType);
  }
};
/**
 * Returns a random person type. To start with it returns either A or B
 */
export const getRandomPersonType = () => {
  return Math.floor(Math.random() * 10) % 2 === 0 ? "A" : "B";
};

/**
 * Sets persontype as random
 * @param {*} force
 */
export const setRandomPersonType = () => {
  setPersonType(getRandomPersonType());
};
