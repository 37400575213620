import {
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "mobile-device-detect";
import { isLoggedIn } from "utils/authentication";
import { mError, mLog } from "utils/logger";
import { SegmentAnalytics } from "./Analytics";

const getDefaultProps = (): any => {
  const authenticated = isLoggedIn();
  const returnObject = {
    State: authenticated ? "Logged In" : "Logged Out",
    os_name: osName,
    os_version: osVersion,
    mobile_model: mobileModel || "NA",
    mobile_vendor: mobileVendor || "NA",
  };
  returnObject["$os"] = osName || "NA";
  returnObject["$screen_height"] = window?.innerHeight || "NA";
  returnObject["$screen_width"] = window?.innerWidth || "NA";
  return returnObject;
};

const actions = {
  identify: (id: string, traits = {}): void => {
    try {
      mLog("Analytics Identify: ", id, traits);
      SegmentAnalytics.identify(id, traits);
    } catch (err) {
      mError("Analytics identify not captured", err);
    }
  },
  alias: (id: string): void => {
    try {
      mLog("Analytics Alias: ", id);
      SegmentAnalytics.alias(id);
    } catch (err) {
      mError("Analytics alias not captured", err);
    }
  },
  track: (name: string, props = {}): void => {
    try {
      const mergedProps = { ...getDefaultProps(), ...props };
      SegmentAnalytics.track(name, mergedProps);
      mLog("Analytics Event: ", name, props);
    } catch (err) {
      mError("Analytics track not captured", err);
    }
  },
  page: (name: string, props = {}): void => {
    try {
      const mergedProps = { ...getDefaultProps(), ...props };
      SegmentAnalytics.page(name, mergedProps);
      mLog("Analytics Page Event: ", name, mergedProps);
    } catch (err) {
      mError("Analytics track not captured", err);
    }
  },
};

export const Analytics = actions;
