import Router from "next/router";
import AppConstants from "services/constants/AppConstants";
import { getCookie, removeCookie, setCookie } from "utils/cookie-manager";

const getAuthToken = (req?: any): string | undefined => {
  return getCookie(AppConstants.AUTH_ACCESS_COOKIE_NAME, req);
};

const setAuthToken = (token: string) => {
  if (token) {
    setCookie("access_token", token);
  }
};

const removeAuthToken = (req?: any) => {
  removeCookie(AppConstants.AUTH_ACCESS_COOKIE_NAME, req);
};

export const isLoggedIn = (req = {}): boolean => {
  return getCookie(AppConstants.AUTH_ACCESS_COOKIE_NAME, req) ? true : false;
};

const RedirectionHelper = (response, redirectionRoute: string): void => {
  const router = Router;
  if (typeof window === "undefined") {
    response.writeHead(303, {
      Location: redirectionRoute,
    });
    response.end();
  } else {
    router.push(redirectionRoute);
  }
};

export { getAuthToken, RedirectionHelper, removeAuthToken, setAuthToken };
