// In case of changes in the environment variables (addition/deletion), changes have to be made here
//  as well for client side accessiblity
const config = {
  BASE_URL: process.env.NEXT_PUBLIC_URL as string,
  API_URL: process.env.NEXT_PUBLIC_BASE_URL as string,
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN as string,
  SEGMENT_ID: process.env.NEXT_PUBLIC_SEGMENT_ID as string,
  IS_PROD: process.env.NEXT_PUBLIC_IS_PROD as string,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN as string,
  OPTIMIZE_ID: process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID as string,
  BEAMER_ID: process.env.NEXT_PUBLIC_BEAMER_ID as string,
  LINKEDIN_CLIENT_ID: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID as string,
  ZIPPY_KEY: process.env.NEXT_PUBLIC_ZIPPY_KEY as string,
  FB_PIXEL_ID: process.env.NEXT_PUBLIC_FB_PIXEL_ID as string,
  GA_ID: process.env.NEXT_PUBLIC_GA_ID as string,
  MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE as string,
  RAZORPAY_KEY: process.env.RAZORPAY_KEY as string,
  CAROUSEL_API_URL: process.env.NEXT_PUBLIC_CAROUSEL_API_URL as string,
};

export default config;
