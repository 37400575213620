import "@styles/rootVars.scss";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Analytics as NextAnalytics } from "@vercel/analytics/react";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import { ANT_CUSTOM_THEME } from "configs/AntThemeConfig";
import { AnimatePresence } from "framer-motion";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { Router, useRouter } from "next/router";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { Routes } from "services/constants/RouteConstants";
import "styles/global.scss";
import { AnalyticsProvider, insertSpaces } from "utils/Analytics/Analytics";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import config from "utils/config";
import { setCookie } from "utils/cookie-manager";

const MaintenanceMode = dynamic(
  () => import("components/Common/MaintenanceMode"),
  {
    ssr: false,
  }
);

// Create a client for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 2 * (60 * 1000), // 2 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    },
  },
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { query } = router;
  const isMaintenanceMode = config.MAINTENANCE_MODE === "true" ? true : false;
  const GOOGLE_ANALYTICS_ID = config.GA_ID ?? "";

  ReactGA.initialize(GOOGLE_ANALYTICS_ID);

  const storeUTMParameters = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCookies = [
      "utm_source",
      "utm_page",
      "utm_medium",
      "utm_campaign",
      "http_referer",
    ];
    if (query.http_referrer) {
      setCookie("http_referrer", urlParams.get("http_referrer") || "");
    }
    utmCookies.forEach((name) => {
      if (urlParams.get(name)) {
        setCookie(name, urlParams.get(name) || "");
      }
    });
  };

  const logPageLoadEvents = (): void => {
    const match = Routes.find((route) => route.pattern === location?.pathname);
    const currentPage: string = match?.name ? insertSpaces(match?.name) : "";
    ReactGA.set({ page: location?.pathname }); // Update the user's current page
    ReactGA.pageview(window.location.pathname);
    Analytics.page(currentPage);
    Analytics.track("pageViewed", { path: location?.pathname });
  };

  useEffect(() => {
    setTimeout(() => {
      storeUTMParameters();
      logPageLoadEvents();
    });

    Router.events.on("routeChangeComplete", () => {
      storeUTMParameters();
      logPageLoadEvents();
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <AnalyticsProvider writeKey={config.SEGMENT_ID || ""}>
          <ConfigProvider theme={ANT_CUSTOM_THEME}>
            <AnimatePresence mode="wait">
              {isMaintenanceMode ? (
                <MaintenanceMode />
              ) : (
                <Component {...pageProps} key={router.asPath} />
              )}
              <NextAnalytics />
            </AnimatePresence>
          </ConfigProvider>
        </AnalyticsProvider>
        {config.IS_PROD === "false" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </Hydrate>
    </QueryClientProvider>
  );
};

export default MyApp;
